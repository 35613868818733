import React, { useState, useEffect } from "react";
import { Verified } from "@mui/icons-material";
import { Progress, Space, Spin, Typography } from "antd";

import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";
import { useFiltersState } from "src/state/FiltersState";

import { color, elementSize, fontSize, fontWeight } from "src/styles/variables";
import { DashboardActionsCard, OrgContainer } from "./Dashboard.styles";
import {
  countTranscriptsForUser,
  countVerifiedTranscriptsForUser,
} from "src/utils/apiCalls";
import CalendarCards from "./Calendar/CalendarCards";

const Actions = () => {
  const { transcriptsConditions } = useFiltersState();
  const { organization } = useOrganizationState();
  const { user } = useUserState();

  const [totalTranscripts, setTotalTranscripts] = useState(0);
  const [loading, setLoading] = useState(false);

  const [verifiedTranscripts, setVerifiedTranscripts] = useState(0);
  const [progress, setProgress] = useState(0);

  // Function to calculate the progress
  const calculateProgress = (verified, total) => {
    if (total > 0) {
      return Math.round((verified / total) * 100);
    }
    return 0;
  };

  const getColor = (percent) => {
    if (percent === 100) return color.lightGreen;
    if (percent >= 75) return color.orange;
    return color.red;
  };

  useEffect(() => {
    const fetchTranscriptCounts = async () => {
      setLoading(true);
      try {
        // Fetch the total and verified transcript counts asynchronously
        const total = await countTranscriptsForUser(organization, user);
        const verified = await countVerifiedTranscriptsForUser(
          organization,
          user
        );

        // Set the values for total and verified transcripts
        setTotalTranscripts(total);
        setVerifiedTranscripts(verified);

        // Calculate progress and set the state

        setProgress(calculateProgress(verified, total));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching transcript counts:", error);
        setLoading(false);
      }
    };

    // Call the fetch function when the component mounts or when dependencies change
    fetchTranscriptCounts();
  }, [organization, user, transcriptsConditions]);

  return (

    <>
      <OrgContainer>
        <DashboardActionsCard>
          <Space align="start">
            <Verified style={{ color: color.lightOrange }} />
            <Space direction="vertical">
              <Typography.Text strong>My Assignments</Typography.Text>

              <Typography.Text type="secondary">
                All time
              </Typography.Text>
            </Space>
          </Space>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: elementSize.sm,
            }}
          >
            <div>
              <div
                style={{
                  fontSize: fontSize.displayXL,
                  fontWeight: fontWeight.semiBold,
                  marginBottom: elementSize.xs,
                }}
              ></div>
            </div>
          </div>
          <Spin spinning={loading}>
            <Space align="center">
              {totalTranscripts > 0 ? (
                <>
                  <Progress
                    style={{ width: 180 }}
                    percent={progress}
                    type="line"
                    strokeColor={getColor(progress)}
                    size="small"
                    percentPosition={{ align: "start", type: "outer" }}
                  />
                  <div
                    style={{ fontSize: "12px" }}
                  >{`${verifiedTranscripts}/${totalTranscripts}`}</div>
                </>
              ) : (
                <p style={{ fontWeight: fontWeight.semiBold }}>
                  No Assignments assigned yet!
                </p>
              )}
            </Space>
          </Spin>
        </DashboardActionsCard>
      </OrgContainer>
      <OrgContainer>
        <CalendarCards />
      </OrgContainer>
    </>
  );
};

export default Actions;

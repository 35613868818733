import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Spin, message, Card, Statistic, Row, Col } from "antd";
import { PlaylistTitle, PlayListDetails } from "./PlaylistDetails.styles";
import { color, deviceSize, elementSize } from "src/styles/variables";
import { getRecordFromFireStore, updateDocInFireStore } from "src/firebaseAuth";
import { playlistConverter } from "src/utils/converter";
import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";
import { fetchClipUrl, hasPlaylistAccess } from "../Playlist.utils";
import { DeskTopOnly, MobileOnly } from "src/styles/stylingComponents";
import { decryptId } from "src/utils/utils";
import { spacing } from "src/styles/variables";
import { COLLECTION_DATA, PLAYLIST_TYPE } from "src/utils/enums";
import { PlayList } from "src/utils/types";
import { PlaylistActions } from "./PlaylistActions";
import TagsView from "./TagsView";
import { debounce } from "lodash";
import { PlaylistClips } from "./PlaylistClips";
import { PlaylistTrendCard } from "./PlaylistTrendCard";
import SentimentChart from "./SentimentUtterancesChart";
import { useMediaQuery } from "react-responsive";

export enum PLAYLIST_VIEW {
  CLIPS = "clips",
  TAGS = "tags",
}

const PlaylistDetails = () => {
  const { encryptedOrganizationId, encryptedPlaylistId } = useParams();
  const organizationId = decryptId(encryptedOrganizationId);
  const playlistId = decryptId(encryptedPlaylistId);

  const [playlist, setPlaylist] = useState<PlayList | null>(null);

  const [loading, setLoading] = useState(true);
  const { organization, playlists, setPlaylists, transcripts } =
    useOrganizationState();
  const { user } = useUserState();
  const [accessDenied, setAccessDenied] = useState(false);
  const [breadcrumbs, setBreadCrumbs] = useState([]);

  const [viewMode, setViewMode] = useState<PLAYLIST_VIEW>(PLAYLIST_VIEW.CLIPS);
  const [fetchedConversations, setFetchedConversations] = useState({});
  const [playlistUtterances, setPlaylistUtterances] = useState([]);
  const [fetchingUtterances, setFetchingUtterances] = useState(true);
  const isMobile = useMediaQuery({
    maxWidth: `${deviceSize.small_phone_size}`,
  });

  const handleDeleteClip = async (clipId) => {
    const updatedClips = playlist.clips.filter((clip) => clip.id !== clipId);
    await updatePlaylistWithNewClips(updatedClips);
  };
  const hasAccess = hasPlaylistAccess(user, organizationId, playlist);
  const clipsWithAccess = playlist?.clips
    ?.filter(
      (clip) =>
        hasAccess ||
        playlist.type !== PLAYLIST_TYPE.SIGNAL ||
        clip.participants?.includes(user?.email),
    )
    .sort((a, b) => b.order - a.order);

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = useCallback(
    debounce((e) => {
      setSearchValue(e.target.value);
    }, 300), // Wait for 300ms before updating searchValue
    [],
  );

  // Memoize the filtered clips to avoid recalculating on every render
  const filteredClips = useMemo(() => {
    if (!searchValue) return clipsWithAccess; // If no search value, return all clips

    const normalizedSearchValue = searchValue.trim().toLowerCase();

    return clipsWithAccess?.filter(
      (clip) =>
        clip.title?.toLowerCase().includes(normalizedSearchValue) ||
        clip.summary?.toLowerCase().includes(normalizedSearchValue),
    );
  }, [clipsWithAccess, searchValue]);

  useEffect(() => {
    const fetchPlaylist = async () => {
      try {
        const existingPlaylist = playlists?.data?.find(
          (p) => p.id === playlistId,
        );
        if (existingPlaylist) {
          setPlaylist(existingPlaylist);
          existingPlaylist.type === PLAYLIST_TYPE.SIGNAL &&
            setViewMode(PLAYLIST_VIEW.TAGS);
        } else {
          const doc = await getRecordFromFireStore(
            `/organization/${organizationId}/${COLLECTION_DATA.PLAYLISTS}/${playlistId}`,
            playlistConverter,
          );

          if (doc) {
            const hasDocAccess = hasPlaylistAccess(user, organizationId, doc);
            if (!hasDocAccess) {
              setAccessDenied(true);
              setLoading(false);
              return;
            }
          }
          setPlaylist(doc);
          doc.type === PLAYLIST_TYPE.SIGNAL && setViewMode(PLAYLIST_VIEW.TAGS);
        }
        setLoading(false);
      } catch (error) {
        message.error("Failed to load playlist. Please try again.");
        console.error("Error fetching playlist: ", error);
        setLoading(false);
      }
    };

    fetchPlaylist();
  }, [playlistId, organizationId, playlists, user?.email]);

  // New optimization for fetching clip URLs
  useEffect(() => {
    fetchAndUpdatePlaylistClips();
  }, [playlist]);

  useEffect(() => {
    fetchTranscriptData();
  }, [playlist, transcripts]);

  const fetchAndUpdatePlaylistClips = async () => {
    if (!playlist || !playlist.clips) return;

    const clipsWithoutUrls = playlist.clips.filter((clip) => !clip?.url);
    if (clipsWithoutUrls.length === 0) return;

    // Fetch URLs for all clips without a URL in parallel
    const updatedClips = await Promise.all(
      playlist.clips.map(async (clip) => {
        if (clip?.url) return clip;
        const updatedClip = await fetchClipUrl(clip);
        return updatedClip;
      }),
    );

    // Only update the playlist if some clips were updated
    setPlaylist((prevState) => ({
      ...prevState,
      clips: updatedClips,
    }));
  };

  const fetchTranscriptData = async () => {
    if (!playlist || !playlist.clips || !transcripts) return;

    setFetchingUtterances(true);

    const conversationData = await Promise.all(
      playlist.clips.map(async (clip) => {
        const existingTranscript = transcripts?.find(
          (transcript) => transcript.id === clip.conversationId,
        );

        if (existingTranscript) {
          return existingTranscript.utterances;
        } else {
          // If not, fetch the conversation data
          const utterances = await fetchConversationData(
            clip.conversationId,
            clip,
          );
          if (utterances && utterances.length > 0) {
            return utterances;
          }
          return null;
        }
      }),
    );

    const validData = conversationData.filter((data) => data !== null).flat();
    setFetchingUtterances(false);

    setPlaylistUtterances(validData);
  };
  const fetchConversationData = async (conversationId, clip) => {
    if (fetchedConversations[conversationId]) {
      return fetchedConversations[conversationId];
    }

    const transcript = await getRecordFromFireStore(
      `organization/${organizationId}/${COLLECTION_DATA.CONVERSATIONS}/${conversationId}`,
    );

    setFetchedConversations((prev) => ({
      ...prev,
      [conversationId]: transcript,
    }));

    let utterances = transcript?.transcript_data?.utterances;

    if (clip.timeline && clip.timeline.length > 0) {
      utterances = transcript?.transcript_data?.utterances.filter(
        (utterance) => {
          return clip.timeline.some(
            (timeRange) =>
              utterance.start >= timeRange.start &&
              utterance.end <= timeRange.end,
          );
        },
      );
    } else {
      utterances = transcript?.transcript_data?.utterances.filter(
        (utterance) =>
          utterance.start >= clip.start && utterance.end <= clip.end,
      );
    }

    return utterances;
  };
  const updatePlaylistWithNewClips = async (newClips) => {
    const updatedPlaylist: PlayList = {
      ...playlist,
      clips: newClips.map((clip, index) => ({
        ...clip,
        updatedAt: new Date().toISOString(),
        order: index + 1,
      })),
    };

    setPlaylist(updatedPlaylist);

    setPlaylists((prevPlaylists) => {
      if (!prevPlaylists || !prevPlaylists.data) {
        // Early return if prevPlaylists or prevPlaylists.data is undefined
        return prevPlaylists;
      }

      // Proceed with updating the playlist if prevPlaylists and prevPlaylists.data exist
      return {
        ...prevPlaylists,
        data: prevPlaylists.data.map((p) =>
          p.id === playlist.id ? updatedPlaylist : p,
        ),
      };
    });

    try {
      await updateDocInFireStore(
        `/organization/${organizationId}/${COLLECTION_DATA.PLAYLISTS}/${playlistId}`,
        updatedPlaylist,
        playlistConverter,
      );
      message.success("Playlist order updated.");
    } catch (error) {
      message.error("Failed to update playlist order.");
      console.error("Error updating playlist order: ", error);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedClips = Array.from(playlist.clips);

    const sourceIndex = reorderedClips.findIndex(
      (clip) => clip.id === clipsWithAccess[result.source.index].id,
    );
    const destinationIndex = reorderedClips.findIndex(
      (clip) => clip.id === clipsWithAccess[result.destination.index].id,
    );

    const [removed] = reorderedClips.splice(sourceIndex, 1);
    reorderedClips.splice(destinationIndex, 0, removed);

    await updatePlaylistWithNewClips(reorderedClips);
  };

  if (loading) return <Spin spinning={loading} />;

  if (accessDenied)
    return <div>You do not have access to view this playlist.</div>;

  if (!playlist) return <div>Playlist not found.</div>;
  return (
    <div
      style={{
        padding: elementSize.sm,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!(breadcrumbs?.length > 0) && (
        <>
          <MobileOnly>
            <PlaylistActions
              viewMode={viewMode}
              playlist={playlist}
              setViewMode={setViewMode}
              setLoading={setLoading}
              handleSearch={handleSearch} // Pass search handler to PlaylistActions
            />
          </MobileOnly>

          <PlayListDetails
            style={{
              padding: elementSize.xxs,
              marginBottom: elementSize.xs,
            }}
          >
            <PlaylistTitle>{playlist?.title} </PlaylistTitle>
            <div style={{ color: color.grayMedium }}>
              {playlist.description}{" "}
            </div>
          </PlayListDetails>

          <DeskTopOnly>
            <PlaylistActions
              viewMode={viewMode}
              playlist={playlist}
              setViewMode={setViewMode}
              setLoading={setLoading}
              handleSearch={handleSearch} // Pass search handler to PlaylistActions
            />
          </DeskTopOnly>
          <Row
            gutter={[16, 16]}
            style={{
              margin: `${spacing.lg} 0`,
              textAlign: "center",
              justifyContent: "center",
              alignContent: "stretch", // Changed to stretch
            }}
          >
            <Col
              xl={{ span: 6 }}
              xs={24}
              style={{ paddingLeft: 0, display: "flex" }}
            >
              <Card
                key={"Volume"}
                title={"Volume"}
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Statistic
                    title={"Total Clips"}
                    value={playlist?.clips?.length}
                  />
                </div>
              </Card>
            </Col>
            <Col xl={{ span: 6 }} xs={24} style={{ display: "flex" }}>
              <Card
                title={"Sentiment"}
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ flex: 1 }}>
                  {fetchingUtterances ? (
                    <Spin spinning={fetchingUtterances} />
                  ) : (
                    <SentimentChart utterances={playlistUtterances} />
                  )}
                </div>
              </Card>
            </Col>
            <Col xl={{ span: 12 }} xs={24} style={{ display: "flex" }}>
              <Card
                title={"Trend"}
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ flex: 1 }}>
                  <PlaylistTrendCard
                    playlist={playlist}
                    numDays={isMobile ? 60 : 90}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </>
      )}

      <div style={{ flex: 1 }}>
        {viewMode === PLAYLIST_VIEW.CLIPS && (
          <PlaylistClips
            clips={filteredClips}
            handleDeleteClip={handleDeleteClip}
            onDragEnd={onDragEnd}
            disabled={organization !== organizationId}
          />
        )}
        {viewMode === PLAYLIST_VIEW.TAGS && playlist?.clips && (
          <TagsView
            organizationId={organizationId}
            playlistId={playlistId}
            clips={playlist.clips}
            breadcrumbs={breadcrumbs}
            setBreadCrumbs={setBreadCrumbs}
            playlistTitle={playlist.title}
            searchValue={searchValue} // Pass search term for tag filtering
          />
        )}
      </div>
    </div>
  );
};

export default PlaylistDetails;

import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Row,
  Col,
  Input,
  DatePicker,
  DatePickerProps,
  Tooltip,
  Progress,
} from "antd";
import { SearchOutlined, ClockCircleOutlined } from "@ant-design/icons";
import MaterialReactTable from "material-react-table"; // Make sure to install material-react-table
import { CollaboratorAvatar, Loading, YesNoIcon } from "src/components";
import { ACCESS_TYPE, COLLECTION_DATA } from "src/utils/enums";
import { useOrganizationState } from "src/state/OrganizationState";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import { getRecordFromFireStore } from "src/firebaseAuth";
import { mapAndSortUsersMetric } from "src/utils/converter";
import { getUsersEmailDictionary } from "src/utils/utils";
import { useAgentDateSelection } from "src/state/UseAgentDateSelection";
import { elementSize, color } from "src/styles/variables";

export const AgentsDashboard = () => {
  const {
    collaborators,
    pendingCollaborators,
    organizationSettings,
    organization,
  } = useOrganizationState();
  const navigate = useNavigate();
  const [searchAgent, setSearchAgent] = useState("");
  const [combinedAgents, setCombinedAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const { date: agentDateFilter, setDate: setAgentDateFilter } =
    useAgentDateSelection();

  const onDateChange: DatePickerProps["onChange"] = (date: Dayjs | null) => {
    if (!date) {
      setOpen(true);
    } else {
      setAgentDateFilter(dayjs(date).startOf("week").startOf("day"));
    }
  };
  const handleOpenChange = (openStatus: boolean) => {
    setOpen(openStatus);
  };

  const scoreType = organizationSettings?.showRisk ? "Risk" : "Score";

  const allAgents = useMemo(
    () =>
      [...(collaborators ?? []), ...(pendingCollaborators ?? [])].filter(
        (user) => user.accessType === ACCESS_TYPE.agent,
      ),
    [collaborators, pendingCollaborators],
  );
  const filteredAgents = useMemo(() => {
    if (!searchAgent.trim()) return combinedAgents;
    const searchTermLower = searchAgent.toLowerCase().trim();
    return combinedAgents.filter((agent: any) =>
      agent?.name.toLowerCase().includes(searchTermLower),
    );
  }, [searchAgent, combinedAgents]);

  useEffect(() => {
    // Fetch insights for the current week
    const fetchInsights = async () => {
      setLoading(true);
      const doc = await getRecordFromFireStore(
        `organization/${organization}/${
          COLLECTION_DATA.INSIGHTS
        }/${agentDateFilter.format("YYYY-MM-DD")}`,
      );
      const docsAgentMetrics = doc?.agentMetrics || [];
      const agentMetrics = mapAndSortUsersMetric(
        docsAgentMetrics,
        getUsersEmailDictionary(allAgents, []),
        organizationSettings?.showRisk,
      );

      const allAgentsMap = new Map();
      allAgents.forEach((agent) => {
        allAgentsMap.set(agent.email, agent);
      });

      const combined = agentMetrics.map((leaderBoardAgent, index) => {
        const agent = allAgentsMap.get(leaderBoardAgent.User.email) || {
          email: leaderBoardAgent.User.email,
          name: leaderBoardAgent.User.name,
        };
        return {
          ...agent,
          ...leaderBoardAgent,
          rank: index + 1,
        };
      });

      allAgents.forEach((agent) => {
        const existsInLeaderBoard = agentMetrics.some(
          (leaderBoardAgent) => leaderBoardAgent.User.email === agent.email,
        );
        if (!existsInLeaderBoard) {
          combined.push({
            ...agent,
            "Total Transcripts": 0,
            "Total Duration": "-",
            "Average Duration": "-",
            Score: "",
            rank: combined.length + 1,
          });
        }
      });

      setCombinedAgents(combined);
      setLoading(false);
    };
    allAgents.length > 0 && fetchInsights();
  }, [agentDateFilter, organization, organizationSettings, allAgents]);

  const handleAgentClick = (agent) => {
    navigate(`?user=${encodeURIComponent(agent.email)}`);
  };

  const customWeekStartEndFormat: DatePickerProps["format"] = (value) =>
    `${dayjs(value).startOf("week").format("MM/DD")} ~ ${dayjs(value)
      .endOf("week")
      .format("MM/DD")}`;

  // Define columns for MaterialReactTable
  const columns = useMemo(
    () => [
      {
        accessorKey: "rank",
        header: "Rank",
      },
      {
        accessorKey: "name",
        header: "Agent Details",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <CollaboratorAvatar collaborator={row.original} />
            <div style={{ marginLeft: "8px" }}>
              <Tooltip title={row.original.name}>
                <div>{row.original.name}</div>
              </Tooltip>
              <Tooltip title={row.original.email}>
                <div style={{ fontSize: "12px", opacity: 0.7 }}>
                  {row.original.email}
                </div>
              </Tooltip>
            </div>
          </div>
        ),
      },
      {
        accessorKey: scoreType,
        header: scoreType,
        Cell: ({ row }) => {
          const score = row.original.Score;

          const percent =
            score != null && score !== "N/A" ? row.original.Score : 0;
          const format =
            score != null && score !== "N/A"
              ? (percent) => {
                  const successPercent = organizationSettings?.showRisk
                    ? 0
                    : 100;
                  const failPercent = organizationSettings?.showRisk ? 100 : 0;
                  const normalPercent =
                    percent !== failPercent && percent !== successPercent;
                  return normalPercent ? (
                    `${percent}%`
                  ) : (
                    <YesNoIcon value={percent === successPercent} />
                  );
                }
              : () => "N/A";
          return (
            <Progress
              type="dashboard"
              steps={5}
              percent={percent}
              strokeColor={
                organizationSettings?.showRisk ? color.orange : color.olive
              }
              trailColor="rgba(0, 0, 0, 0.06)"
              size={40}
              strokeWidth={10}
              format={format}
            />
          );
        },
        size: 20,
        isDynamic: false, // Default column
      },
      {
        accessorKey: "Total Transcripts",
        header: "Transcripts",
      },
      {
        accessorKey: "Total Duration",
        header: "Total Duration",
        Cell: ({ row }) => (
          <div>
            <ClockCircleOutlined style={{ marginRight: 4 }} />
            {row.original["Total Duration"]}
          </div>
        ),
      },
      {
        accessorKey: "Average Duration",
        header: "Average Duration",
        Cell: ({ row }) => (
          <div>
            <ClockCircleOutlined style={{ marginRight: 4 }} />
            {row.original["Average Duration"]}
          </div>
        ),
      },
    ],
    [scoreType],
  );

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Card style={{ padding: `0px ${elementSize.sm}` }}>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: 16 }}
          >
            <Col>
              <Input
                placeholder="Find an agent by name..."
                prefix={<SearchOutlined />}
                value={searchAgent}
                onChange={(e) => setSearchAgent(e.target.value)}
                style={{ width: 300, marginBottom: 8 }}
              />
              <div>{filteredAgents.length} agents found</div>
            </Col>
            <DatePicker
              onChange={onDateChange}
              open={open}
              picker="week"
              value={agentDateFilter}
              format={customWeekStartEndFormat}
              onOpenChange={handleOpenChange}
            />
          </Row>
          <MaterialReactTable
            columns={columns}
            data={filteredAgents}
            enableRowSelection={false}
            enablePagination={false}
            renderTopToolbar={false}
            muiTablePaperProps={{
              elevation: 0,
              sx: {
                boxShadow: "0",
                height: "100%",
              },
            }}
            muiTableBodyProps={{
              sx: {
                height: "100%",
              },
            }}
            getRowId={(row) => row.email} // Ensure each row has a unique id
            muiTableBodyRowProps={({ row }) => ({
              id: `Agent-${row.original.email}`, // Customize row ID
              onClick: () => handleAgentClick(row.original), // Handle row click
              sx: {
                cursor: "pointer", // Make rows appear clickable
                boxShadow: "0 0 0 0!important", // Example styling for the row
              },
            })}
          />
        </Card>
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Card, Row, Col, Segmented, Table, Typography, Tooltip } from "antd";
import { useUserState } from "src/state/UserState";
import { Chart } from "react-google-charts";
import { DownloadCSV, Leaderboard, Loading } from "src/components";

import { generatePlaylistShareableLink } from "../Playlist/Playlist.utils";

import { getUsersEmailDictionary, isEmptyObject } from "src/utils/utils";
import dayjs from "dayjs";

import {
  TopMetricsComponent,
  trucoMetricColumns,
  WeeklyOverViewGraphs,
  getWeeklyGraphData,
  getAggregateValueFromTrancripts,
  getAnalysisScores,
  getUserMetrics,
  getTrucoMetrics,
  getQAAgentMetrics,
  EvaluationSummaryCard,
  processPlaylistsData,
} from "./BusinessInsights.utils";
import { ACCESS_TYPE, COLLECTION_DATA } from "src/utils/enums";
import { useOrganizationState } from "src/state/OrganizationState";
import { useFiltersState } from "src/state/FiltersState";
import { getRecordsFromFireStore } from "src/firebaseAuth";
import { transformInsights } from "src/utils/converter";
import { DashboardActions, DASHBOARD_TYPE } from "../Dashboard/DashboardActions/DashboardActions";

export const MetricTable = ({ title, data, metricColumns }) => {
  return (
    <Col xl={{ span: 12 }} xs={24}>
      <Card
        title={title}
        style={{ overflow: "auto", height: "500px" }}
        extra={[<DownloadCSV data={data} fileName={title} />]}
      >
        <Table
          dataSource={data}
          columns={metricColumns}
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Card>
    </Col>
  );
};

export const StyledSegmented = styled(Segmented)`
  .ant-segmented-item-label {
    height: 100%;
  }
`;
export const BusinessInsights = () => {
  const { user } = useUserState();
  const {
    organization,
    insights,
    setInsights,
    organizationSettings,
    collaborators,
    pendingCollaborators,
    transcripts,
  } = useOrganizationState();
  const showRisk = organizationSettings?.showRisk;
  const { filterDates, transcriptsConditions } = useFiltersState();
  const [currentInsightData, setCurrentInsightData] = useState(
    insights?.selectedDateInsights,
  );

  const [filteredTranscripts, setFilteredTranscripts] = useState(transcripts);
  const handleFilterChange = (filteredData) => {
    setFilteredTranscripts(filteredData);
    if (insights?.showFilterTranscripts) {
      setCurrentInsightData(getInsightsFromTranscripts(filteredData));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try { 
        const queryConstraints = transcriptsConditions[DASHBOARD_TYPE.INSIGHTS].map((item, index) => ({
          ...item,
          comparisonField: "__name__",
          order: index === 0 && "asc",
          value:
            index === 0
              ? dayjs(item.value).subtract(4, "week").format("YYYY-MM-DD")
              : dayjs(item.value).add(4, "week").format("YYYY-MM-DD"),
        }));
        const docs = (
          await getRecordsFromFireStore(
            `organization/${organization}/${COLLECTION_DATA.INSIGHTS}`,
            queryConstraints,
          )
        ).data;

        const InsightsOfSelectedDates = docs.filter((insight) => {
          const startDate = dayjs(filterDates[DASHBOARD_TYPE.INSIGHTS]?.startDate).format("YYYY-MM-DD");
          const endDate = filterDates[DASHBOARD_TYPE.INSIGHTS]?.endDate
            ? dayjs(filterDates[DASHBOARD_TYPE.INSIGHTS]?.endDate).format("YYYY-MM-DD")
            : null;
          return insight.id >= startDate && (!endDate || insight.id <= endDate);
        });
        const selectedDateInsight = transformInsights(
          InsightsOfSelectedDates,
          getUsersEmailDictionary(collaborators, pendingCollaborators),
          showRisk,
        );
        setCurrentInsightData(selectedDateInsight);
        setInsights({
          filterDates: filterDates[DASHBOARD_TYPE.INSIGHTS],
          organization,
          allInsightsData: docs,
          selectedDateInsights: selectedDateInsight,
          Loading: false,
          showFilterTranscripts: false,
        });
      } catch (error) {
        console.error("Error fetching documents: ", error);
      }
    };
    if (
      !insights ||
      !insights.allInsightsData ||
      insights.loading ||
      insights.organization !== organization ||
      insights.filterDates?.startDate !== filterDates?.[DASHBOARD_TYPE.INSIGHTS]?.startDate ||
      insights.filterDates?.endDate !== filterDates?.[DASHBOARD_TYPE.INSIGHTS]?.endDate
    ) {
      setCurrentInsightData(null);
      organizationSettings && fetchData();
    }
  }, [filterDates, organization, organizationSettings]);

  const getInsightsFromTranscripts = (filteredTranscripts) => {
    const { totalDuration, totalVerifiedCount, sentiments } =
      getAggregateValueFromTrancripts(filteredTranscripts);
    const analysisScores = getAnalysisScores(filteredTranscripts);
    let trucoMetrics = null;
    let agentMetrics = [];
    let qaMetrics = [];
    let leadMetrics = [];
    if (user?.accessType !== ACCESS_TYPE.agent) {
      agentMetrics = getUserMetrics(
        filteredTranscripts,
        showRisk,
        ACCESS_TYPE.agent,
      );
      trucoMetrics = getTrucoMetrics(filteredTranscripts, analysisScores);
      qaMetrics = getQAAgentMetrics(filteredTranscripts);
      leadMetrics = getUserMetrics(
        filteredTranscripts,
        showRisk,
        ACCESS_TYPE.lead,
      );
    }
    return {
      totalTranscripts: filteredTranscripts.length,
      totalDuration,
      totalVerifiedCount,
      sentiments,
      analysisScores,
      agentMetrics,
      qaMetrics,
      leadMetrics,
      trucoMetrics,
    };
  };
  const handleDataFromInsights = () => {
    setCurrentInsightData(getInsightsFromTranscripts(filteredTranscripts));

    setInsights((prevInsights) => ({
      ...prevInsights,
      showFilterTranscripts: true,
    }));
  };

  let leaderboardMetrics;
  const scoreType = showRisk ? "Risk" : "Score";

  if (user?.accessType !== ACCESS_TYPE.agent) {
    leaderboardMetrics = [
      [currentInsightData?.agentMetrics, scoreType, "Agents"],
      [currentInsightData?.leadMetrics, scoreType, "Team Leads"],
      [currentInsightData?.qaMetrics, "Verified", "Reviewers"],
    ];
  }
  const { sentimentsData, overallScoreData } = getWeeklyGraphData(
    insights,
    showRisk,
  );

  const playlistTableColumns = [
    {
      title: "Signal",
      dataIndex: "signal",
      sorter: (a, b) => a.signal.localeCompare(b.signal),
      render: (signal, record) => (
        <Typography.Link
          href={generatePlaylistShareableLink(organization, record.playlistId)}
        >
          {signal}
        </Typography.Link>
      ),
    },
    {
      title: "Count",
      dataIndex: "count",
      defaultSortOrder: "descend", // This is correct
      sorter: (a, b) => a.count - b.count,
    },
    {
      title: "Summary",
      dataIndex: "summary",
      sorter: (a, b) => a.summary.localeCompare(b.summary),
      render: (text) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 3, // Number of lines to show
              WebkitBoxOrient: "vertical",
              lineHeight: "1.5em", // Adjust line height if needed
              maxHeight: "4.5em", // Should be 3 * lineHeight
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
  ];
  const playlistTableData = insights?.selectedDateInsights?.playlists
    ? insights?.selectedDateInsights?.playlists.map((playlist) => ({
        signal: playlist.title,
        count: playlist?.count,
        summary: playlist?.summary,
        playlistId: playlist?.id,
      }))
    : [];

  return (
    <>
      {!currentInsightData || insights?.loading ? (
        <Loading />
      ) : (
        <Card>
          <DashboardActions
            type={ DASHBOARD_TYPE.INSIGHTS }
            transcripts={transcripts}
            handleFilterChange={handleFilterChange}
            showFiltersTranscripts={
              transcripts ? insights?.showFilterTranscripts : false
            }
            onFilterShowClick={handleDataFromInsights}
          />
          <Row gutter={[16, 32]}>
            {currentInsightData.totalTranscripts > 0 && (
              <TopMetricsComponent
                insights={currentInsightData}
                showRisk={showRisk}
              />
            )}

            {playlistTableData?.length > 0 && (
              <>
                <Col xl={{ span: 12 }} xs={24} key={"Signal Count"}>
                  <Card
                    title={"Signals"}
                    style={{ overflow: "auto", height: "500px" }}
                  >
                    <Chart
                      chartType="Line"
                      data={processPlaylistsData(insights.allInsightsData)}
                      options={{
                        hAxis: { title: "Signal Count", minValue: 0 },
                        vAxis: { title: "Signal", minValue: 0 },
                        legend: { position: "top" }, // Legend above the chart
                      }}
                      graph_id="Line"
                      width={"100%"}
                      height={"400px"}
                      legend_toggle
                    />
                  </Card>
                </Col>
                <MetricTable
                  title={"Signals Summary"}
                  data={playlistTableData}
                  metricColumns={playlistTableColumns}
                />
              </>
            )}

           {!isEmptyObject(currentInsightData.analysisScores) && <EvaluationSummaryCard
              currentInsightData={currentInsightData}
              showRisk={showRisk}
            />}

            {!insights?.showFilterTranscripts && (
              <WeeklyOverViewGraphs
                // sentiments={sentimentsData}
                overallScoreData={overallScoreData}
                scoreType={scoreType}
              />
            )}

            {leaderboardMetrics &&
              leaderboardMetrics.map(([metrics, scoreType, title]) => {
                if (metrics.length <= 1) return null;
                return (
                  <Col xl={{ span: 12 }} xs={24} key={title + "leaderboard"}>
                    <Card title={title + " Leader Board"}>
                      {metrics.length > 0 ? (
                        <Leaderboard
                          leaderboardUsers={metrics}
                          scoreType={scoreType}
                        />
                      ) : (
                        `No ${title} are in the leaderboard`
                      )}
                    </Card>
                  </Col>
                );
              })}

            {[ACCESS_TYPE.owner, ACCESS_TYPE.trucoAdmin].includes(
              user?.accessType,
            ) &&
              currentInsightData.trucoMetrics?.length > 0 && (
                <MetricTable
                  title={"Truco Verified Metrics"}
                  data={currentInsightData.trucoMetrics}
                  metricColumns={trucoMetricColumns}
                />
              )}
          </Row>
        </Card>
      )}
    </>
  );
};

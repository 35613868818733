import React, { useEffect, useState } from "react";
import { Button, Modal, Breadcrumb, Row, Collapse, Tooltip, Col } from "antd";
import { Link } from "react-router-dom";
import MediaPlaylist from "../Dashboard/ConversationModal/Playlist/MediaPlaylist";
import { StyledTextArea } from "../Profile/Profile.styles";
import { ConversationTable } from "../Dashboard/ConversationTable";
import { HomeOutlined } from "@ant-design/icons";
import { useOrganizationState } from "src/state/OrganizationState";
import { getRecordFromFireStore } from "src/firebaseAuth";
import { transcriptConverter } from "src/utils/converter";
import { useUserState } from "src/state/UserState";
import { COLLECTION_DATA } from "src/utils/enums";
import { getUsersEmailDictionary } from "src/utils/utils";
import { TranscriptBox } from "./Evaluation.styles";
import Thumbnail from "../Playlist/PlaylistDetails/Thumbnail/Thumbnail";
import { DeleteWithPopConfirm } from "src/components";
import { fetchFileUrl } from "../Dashboard/Dashboard.utils";
import { EyeOutlined } from "@ant-design/icons";
import { DashboardActions, DASHBOARD_TYPE } from "../Dashboard/DashboardActions/DashboardActions";

interface ExamplesListProps {
  items: string[];
  setItems: (items: string[]) => void;
  disabled: boolean;
}

const ExamplesList: React.FC<ExamplesListProps> = ({
  items,
  setItems,
  disabled,
}) => {
  const [showModal, setShowModal] = useState(false);
  const {
    transcripts,
    organization,
    evaluationForms,
    collaborators,
    pendingCollaborators,
  } = useOrganizationState();
  const [selectedId, setSelectedId] = useState<string | null | undefined>(null);
  const [modalData, setModalData] = useState<any>(null);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [disableButton, setDisableButton] = useState(true);
  const [exampleSummaryValue, setExampleSummaryValue] = useState("");
  const { user } = useUserState();
  const { Panel } = Collapse;
  const [showModalExample, setShowModalExample] = useState(false);
  const [dataForm, setDataForm] = useState<any>([]);
  const [filteredData, setFilteredData] = useState(transcripts);

  const fetchTranscripts = async (selectedId) => {
    const fetchedTranscript = await getRecordFromFireStore(
      `organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}/${selectedId}`,
      transcriptConverter(
        user,
        evaluationForms,
        getUsersEmailDictionary(collaborators, pendingCollaborators),
      ),
    );

    setModalData({
      ...fetchedTranscript,
      id: selectedId,
    });
  };

  useEffect(() => {
    if (selectedId) {
      fetchTranscripts(selectedId);
    }
  }, [selectedId]);

  const handleRowClick = (transcript) => {
    setSelectedId(transcript?.id);
  };

  const handleAddItem = () => {
    setShowModal(true);
  };

  const handleAddExample = () => {
    const newExample: any = {
      transcripts: modalData.utterances
        .filter(
          (utterance) =>
            utterance.end >= startTime && utterance.start <= endTime,
        )
        .map(
          (utterance) =>
            `${typeof utterance["speaker"] === "string" ? utterance["speaker"] : `Speaker ${utterance["speaker"]}`}: ${utterance.transcript}`,
        ),
      startTime,
      endTime,
      conversationId: selectedId,
      mimeType: modalData.mimeType,
      summary: exampleSummaryValue,
    };
    setItems([...items, newExample]);
    setShowModal(false);
    setSelectedId(null);
  };

  const handleRemoveItem = (index: number) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
    if (newItems.length === 0) {
      setShowModalExample(false);
    }
  };
 
  const handleFilterChange = (filteredData) => {
    setFilteredData(filteredData);
  };

  const fetchUrlsAndUpdateData = async (examples) => {
    const updatedExamples = await Promise.all(
      examples.map(async (data) => {
        if (!data.url && data.conversationId) {
          try {
            const url = await fetchFileUrl(data.conversationId);
            return { ...data, url };
          } catch (error) {
            console.error(
              `Failed to fetch URL for conversationId ${data.conversationId}:`,
              error,
            );
            return data;
          }
        }
        return data;
      }),
    );

    setDataForm(updatedExamples);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Button type="dashed" onClick={handleAddItem} disabled={disabled}>
            Add Examples
          </Button>
        </Col>

        <Col span={24}>
          {items.length > 0 && (
            <Button
              icon={<EyeOutlined />}
              onClick={() => {
                fetchUrlsAndUpdateData(items);
                setShowModalExample(true);
              }}
              disabled={disabled}
            >
              View Examples
            </Button>
          )}
        </Col>
      </Row>

      <Modal
        open={showModal}
        title={
          <Breadcrumb style={{ cursor: "pointer" }}>
            <Breadcrumb.Item>
              <Link
                to="#"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => {
                setSelectedId(null);
              }}
            >
              Choose from conversation
            </Breadcrumb.Item>
            {selectedId && (
              <Breadcrumb.Item>
                <span> {selectedId}</span>
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
        }
        onCancel={() => {
          setSelectedId(null);
          setShowModal(false);
        }}
        footer={null}
        width={"97vw"}
      >
      <DashboardActions
        transcripts={transcripts}
        filteredData={filteredData}
        handleFilterChange={handleFilterChange}
        type={DASHBOARD_TYPE.SIGNALS}
      />
        {!selectedId && filteredData?.length > 0 && (
          <ConversationTable
            data={filteredData}
            showRisk={true}
            handleRowClick={handleRowClick}
          />
        )}

        {selectedId && (
          <>
            <MediaPlaylist
              data={modalData}
              startTime={startTime}
              endTime={endTime}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setDisable={setDisableButton}
            />

            <>
              <h3>Summary</h3>
              <StyledTextArea
                value={exampleSummaryValue}
                onChange={(e) => {
                  setExampleSummaryValue(e.target.value);
                }}
                placeholder="Enter summary"
                autoSize={{ minRows: 2 }}
              />
            </>

            <Row
              justify="end"
              style={{
                marginTop: "10px",
              }}
            >
              <Button
                disabled={disableButton}
                onClick={() => {
                  handleAddExample();
                }}
              >
                Add example
              </Button>
            </Row>
          </>
        )}
      </Modal>

      <Modal
        open={showModalExample}
        onCancel={() => {
          setShowModalExample(false);
        }}
        footer={null}
        width={"97vw"}
      >
        <div
          style={{
            padding: "20px",
          }}
        >
          <Collapse accordion>
            {items?.map((data: any, index) => (
              <Panel
                header={
                  <Row align="middle" justify="space-between">
                    {`Example ${index + 1}`}
                    {!disabled && (
                      <Tooltip title={"Delete Example"}>
                        {DeleteWithPopConfirm(
                          "Delete Example?",
                          "Are you sure you want to delete this Example?",
                          (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleRemoveItem(index);
                          },
                          disabled,
                        )}
                      </Tooltip>
                    )}
                  </Row>
                }
                key={index}
              >
                <Row align="middle">
                  <Col span={8}>
                    {data?.conversationId && (
                      <Thumbnail
                        url={dataForm?.[index]?.url}
                        start={data.startTime}
                        end={data.endTime}
                        type={data.mimeType}
                        clipId={`${index}-${data.conversationId}`}
                      />
                    )}
                  </Col>
                  <Col span={14}>
                    {data?.transcripts?.map((transcript, indexTranscript) => (
                      <TranscriptBox key={indexTranscript}>
                        {transcript}
                      </TranscriptBox>
                    ))}

                    <h4>Summary</h4>
                    <p>{data?.value}</p>
                  </Col>
                </Row>
              </Panel>
            ))}
          </Collapse>
        </div>
      </Modal>
    </>
  );
};

export default ExamplesList;

import { message } from "antd";

export const fetchCalendarEvents = async (calendarId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_TRUCO_BACKEND}/recall/get-calendar-events`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ calendar_id: calendarId }),
      },
    );
    const data = await response.json();

    if (response.ok) {
      return data.results;
    } else {
      console.error("Failed to fetch calendar events:", data.error);
      return [];
    }
  } catch (error) {
    console.error("Error fetching calendar events:", error);
    return [];
  }
};

export const removeBot = async (eventId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_TRUCO_BACKEND}/recall/delete-bot`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ bot_id: eventId }),
      },
    );

    const data = await response.json();
    if (response.ok) {
      message.success(`Bot removed successfully`);
      return true;
    } else {
      message.error(`Failed to remove bot: ${data.error}`);
      return false;
    }
  } catch (error) {
    message.error(`Error removing bot: ${error}`);
    return false;
  }
};

export const scheduleBot = async (
  botId,
  meetingUrl,
  startTime,
  botMetadata,
  organization,
  userName,
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_TRUCO_BACKEND}/recall/schedule-bot`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          bot_id: botId,
          meeting_url: meetingUrl,
          start_time: startTime,
          bot_metadata: botMetadata,
          organization: organization,
          user_name: userName,
        }),
      },
    );

    const data = await response.json();
    if (response.ok) {
      message.success("Bot scheduled successfully");
      return data.data; // Return the schedule bot response data
    } else {
      message.error(`Failed to schedule bot: ${data.error}`);
      return false;
    }
  } catch (error) {
    message.error(`Error scheduling bot: ${error}`);
    return false;
  }
};

export const createBot = async (
  meetingUrl,
  metadata,
  bot_metadata,
  operatorName,
) => {
  const response = await fetch(
    `  ${process.env.REACT_APP_TRUCO_BACKEND}/recall/create-bot`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        meeting_url: meetingUrl,
        metadata: metadata,
        bot_metadata: bot_metadata,
        user_name: operatorName,
      }),
    },
  );

  const data = await response.json();
  if (response.ok) {
    return { success: true, data: data.data }; // Return success status and data
  } else {
    return { success: false, error: data.error }; // Return error status and message
  }
};

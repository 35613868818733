import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Row,
  Col,
  Input,
  Button,
  Tag,
  Tooltip,
  Switch,
  Space,
} from "antd";
import {
  BankOutlined,
  LinkOutlined,
  SearchOutlined,
  PhoneOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { CollaboratorAvatar, Loading } from "src/components";
import { useOrganizationState } from "src/state/OrganizationState";
import { elementSize } from "src/styles/variables";
import MaterialReactTable from "material-react-table";
import { COLLECTION_DATA } from "src/utils/enums";
import {
  createOrUpdateDocInFirestore,
  getRecordsFromFireStore,
} from "src/firebaseAuth";
import { customerConverter } from "src/utils/converter";
import { Customer } from "src/utils/types";
import { DraggableBoard } from "src/components";
import { useNavigate } from "react-router-dom";
import {
  capitalizeFirstLetters,
  formatDateToHumanReadable,
  getColorForValue,
} from "src/utils/utils";
import { getCustomerIdentity } from "./Customers.utils";
import { Container, ItemContainer } from "../Dashboard/Dashboard.styles";
import { TruncateCell } from "../Dashboard/Dashboard.utils";

export const CustomersDashboard = () => {
  const {
    organization,
    customers,
    setCustomers,
    organizationSettings,
    setOrganizationSettings,
  } = useOrganizationState();

  const navigate = useNavigate();
  const [searchCustomer, setSearchCustomer] = useState("");
  const [loading, setLoading] = useState(!customers?.data);
  const [viewMode, setViewMode] = useState("table");
  const [stageData, setStageData] = useState({});

  const columns = [
    {
      header: "Customer",
      accessorFn: (row) => getCustomerIdentity(row),
      size: 100, // specify column width
      Cell: ({ cell }) => {
        const customer = cell.getValue();
        return customer ? customer : "N/A";
      },
    },
    {
      header: "Account",
      accessorFn: (row) => ({
        company: row.company,
        companyDomain: row.companyDomain,
      }),
      size: 100, // specify column width
      Cell: ({ cell }) => (
        <Container>
          {cell.getValue().company && (
            <Tooltip title="Created At">
              <ItemContainer>
                <BankOutlined
                  style={{ marginRight: "8px", color: "#52c41a" }}
                />
                <TruncateCell
                  cell={{ getValue: () => cell.getValue().company }}
                />
              </ItemContainer>
            </Tooltip>
          )}

          {cell.getValue().companyDomain && (
            <Tooltip title="Company Domain">
              <ItemContainer>
                <LinkOutlined
                  style={{ marginRight: "8px", color: "#1890ff" }}
                />
                <TruncateCell
                  cell={{ getValue: () => cell.getValue().companyDomain }}
                />
              </ItemContainer>
            </Tooltip>
          )}
        </Container>
      ),
    },
    {
      header: "Stage",
      accessorKey: "currentStage",
      Cell: ({ cell }) => {
        const stage = cell.getValue();
        return stage ? (
          <Tag color={getColorForValue(stage)}>
            {capitalizeFirstLetters(stage)}
          </Tag>
        ) : (
          ""
        );
      },
    },
    {
      header: "# of Conversations",
      accessorKey: "conversations", // accessor is the "key" in the data
      Cell: ({ cell }) => {
        const conversations = cell.getValue();
        return conversations?.length;
      },
      size: 20, // specify column width
    },
    {
      header: "Update At",
      accessorKey: "updatedAt", // accessor is the "key" in the data
      size: 100, // specify column width
      Cell: ({ cell }) => {
        return formatDateToHumanReadable(cell.getValue());
      },
      enableColumnFilter: false,
    },
  ];

  const [paginationPerStage, setPaginationPerStage] = useState({});

  const fetchCustomers = async (stage = null) => {
    setLoading(true);

    const paginationState = stage
      ? paginationPerStage[stage] || { lastDoc: null, hasMore: true }
      : {
          lastDoc: customers?.lastDoc || null,
          hasMore: customers?.hasMore ?? true,
        };

    const filters: any = [
      {
        comparisonField: "updatedAt",
        order: "desc",
      },
    ];

    if (stage) {
      if (stage === "Unassigned Stage") {
        filters.push({
          comparisonField: "currentStage",
          comparisonOperator: "==",
          value: "",
        });
      } else {
        filters.push({
          comparisonField: "currentStage",
          comparisonOperator: "in",
          value: [capitalizeFirstLetters(stage), stage.toLowerCase()],
        });
      }
    }

    const docs = await getRecordsFromFireStore(
      `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}`,
      filters,
      customerConverter,
      undefined,
      50,
      paginationState.lastDoc,
    );

    if (stage) {
      // Update stage data

      const check = docs.data.filter(
        (newCustomer) =>
          !customers.data.find((customer) => customer.id === newCustomer.id),
      );

      const newData = [...customers.data, ...check];

      // Update overall customers list to include new customers
      setCustomers((prevState) => {
        const newCustomers = docs.data.filter(
          (newCustomer) =>
            !prevState.data.find((customer) => customer.id === newCustomer.id),
        );

        return {
          ...prevState,
          data: [...prevState.data, ...newCustomers],
        };
      });
      setPaginationPerStage((prevPagination) => ({
        ...prevPagination,
        [stage]: {
          lastDoc: docs.lastDoc || null, // Update the specific lastDoc for this stage
          hasMore: docs.data.length >= 50 && docs.data.length > 0, // Check if there are more data
          // hasMore: true,
        },
      }));
      updateStageData(newData);
    } else {
      setCustomers((prevState) => {
        const filteredNewData = docs?.data?.filter(
          (newCustomer) =>
            !prevState?.data?.find(
              (customer) => customer.id === newCustomer.id,
            ),
        );
        const newData = [...(prevState.data || []), ...filteredNewData];
        const hasMore = docs.data.length >= 50 && docs.data.length > 0;

        if (!hasMore) {
          setPaginationPerStage((prevPagination) => {
            const updatedPagination = { ...prevPagination };

            Object.keys(updatedPagination).forEach((stage) => {
              updatedPagination[stage] = {
                hasMore: false,
                lastDoc: null,
              };
            });
            return updatedPagination;
          });
        }

        return {
          ...prevState,
          data: newData.length > 0 ? newData : [],
          hasMore: hasMore,
          lastDoc: docs.lastDoc || null,
        };
      });
      // Update stage data
      updateStageData(docs.data);
    }

    setLoading(false);
  };

  const updateStageData = (customersData) => {
    const newStages = {
      "Unassigned Stage": [],
      ...organizationSettings?.customerStages
        ?.map((stage) => stage.toLowerCase())
        .reduce((acc, stage) => {
          acc[stage] = [];
          return acc;
        }, {}),
    };
    customersData?.forEach((customer) => {
      const stage = customer?.currentStage?.toLowerCase() || "Unassigned Stage";
      if (newStages[stage]) {
        newStages[stage].push(customer);
      }
    });

    setStageData(newStages);
    if (Object.keys(paginationPerStage).length === 0) {
      setPaginationPerStage((prevPagination) => {
        const updatedPagination = { ...prevPagination };

        Object.keys(newStages).forEach((stage) => {
          if (!updatedPagination[stage]) {
            updatedPagination[stage] = {
              hasMore: true,
              lastDoc: null,
            };
          }
        });
        return updatedPagination;
      });
    }
  };

  useEffect(() => {
    if (!organizationSettings || !organization) return;
    if (!customers?.data) {
      fetchCustomers();
    } else {
      updateStageData(customers.data);
    }
  }, [organization, customers?.data, organizationSettings]);

  const loadMoreCustomers = async () => {
    if (!customers.lastDoc) return;
    setLoading(true);
    try {
      fetchCustomers();
      setTimeout(() => {
        const lastBeforeNew = document.getElementById(
          `Customer-${customers.data[customers.data.length - 2]?.id}`,
        );
        if (lastBeforeNew) {
          lastBeforeNew.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    } catch (error) {
      console.error("Error fetching more transcripts: ", error);
    } finally {
      setLoading(false);
    }
  };
  const loadMoreCustomersPerStage = (stage) => {
    fetchCustomers(stage);
  };

  const filteredCustomers = useMemo(() => {
    return customers?.data?.filter((customer) =>
      customer.customer.toLowerCase().includes(searchCustomer.toLowerCase()),
    );
  }, [customers?.data, searchCustomer]);

  const groupedFilteredCustomers = useMemo(() => {
    return Object.entries(stageData).reduce((acc, [stage, customers]: any) => {
      const filteredCustomers = customers.filter((customer) =>
        customer.customer.toLowerCase().includes(searchCustomer.toLowerCase()),
      );

      if (filteredCustomers.length > 0 && searchCustomer) {
        acc[stage] = filteredCustomers;
      }

      acc[stage] = filteredCustomers;

      return acc;
    }, {});
  }, [searchCustomer, stageData]);

  const handleCustomerClick = (customer) => {
    navigate(`?customer=${encodeURIComponent(customer.id)}`);
  };
  const onDragEnd = async (result) => {
    const { source, destination, draggableId, type } = result;
    if (!destination) return;

    const sourceStage = source.droppableId;
    const destinationStage = destination.droppableId;

    if (
      sourceStage === destinationStage &&
      source.index === destination.index
    ) {
      return;
    }

    if (type === "Stage") {
      const stagesArray = Object.keys(stageData);
      const [removed] = stagesArray.splice(source.index, 1);
      stagesArray.splice(destination.index, 0, removed);
      // remove first unassigned stage

      setStageData((prevData) => {
        const updatedData = stagesArray.reduce((acc, stage) => {
          acc[stage] = prevData[stage];
          return acc;
        }, {});
        return updatedData;
      });

      stagesArray.splice(0, 1);

      const lowerCaseStages = stagesArray.map((stage) => stage.toLowerCase());

      setOrganizationSettings((prevSettings) => {
        return { ...prevSettings, customerStages: lowerCaseStages };
      });

      await createOrUpdateDocInFirestore(`/organization/${organization}`, {
        customerStages: stagesArray,
      });

      return;
    }

    const customerData = customers.data.find(
      (customer) => customer.id === draggableId,
    );
    const updatedCustomer = {
      ...customerData,
      currentStage:
        destinationStage === "Unassigned Stage" ? "" : destinationStage,
      updatedAt: new Date().toISOString(),
    };

    setCustomers((prevstate) => {
      const updatedCustomers = prevstate.data.map((customer) => {
        if (customer.id === draggableId) {
          return updatedCustomer;
        }
        return customer;
      });

      updatedCustomers.sort((a, b) => {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return dateB - dateA;
      });

      return {
        ...prevstate,
        data: updatedCustomers,
      };
    });

    createOrUpdateDocInFirestore(
      `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}/${draggableId}`,
      updatedCustomer,
    );
  };

  const renderCustomerCard = (customer) => {
    return (
      <Card hoverable onClick={() => handleCustomerClick(customer)}>
        <Card.Meta
          avatar={<CollaboratorAvatar collaborator={customer} />}
          title={getCustomerIdentity(customer)}
        />
        <Space wrap style={{ marginTop: "8px" }}>
          <Tooltip title="# of Conversations">
            <PhoneOutlined style={{ marginRight: "8px", color: "#52c41a" }} />
            {customer.conversations?.length || 0}
          </Tooltip>
          <Tooltip title="Updated At">
            <CalendarOutlined
              style={{ marginRight: "8px", color: "#52c41a" }}
            />
            {formatDateToHumanReadable(customer.updatedAt)}
          </Tooltip>
          {customer.company && (
            <Tooltip title="Company">
              <BankOutlined style={{ marginRight: "8px", color: "#52c41a" }} />
              {customer.company}
            </Tooltip>
          )}
          {customer.companyDomain && (
            <Tooltip title="Company Domain">
              <LinkOutlined style={{ marginRight: "8px", color: "#1890ff" }} />
              {customer.companyDomain}
            </Tooltip>
          )}
        </Space>
      </Card>
    );
  };

  return (
    <>
      {!customers?.data ? (
        <Loading />
      ) : (
        <Card style={{ padding: `0px ${elementSize.sm}` }}>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: 16 }}
          >
            <Col>
              <Input
                placeholder="Find a Customer by Id ..."
                prefix={<SearchOutlined />}
                value={searchCustomer}
                onChange={(e) => setSearchCustomer(e.target.value)}
                style={{ width: 300, marginBottom: 8 }}
              />
            </Col>
            <Col>
              <Switch
                checked={viewMode === "table"}
                onChange={(checked) => setViewMode(checked ? "table" : "board")}
                checkedChildren="Table View"
                unCheckedChildren="Board View"
              />
            </Col>
          </Row>

          <h3>
            {filteredCustomers?.length > 0 &&
              `Last ${filteredCustomers?.length} Customers`}
          </h3>
          {viewMode === "table" ? (
            <>
              <MaterialReactTable
                columns={columns}
                data={filteredCustomers}
                enableStickyHeader={true}
                enableRowSelection={false}
                rowCount={filteredCustomers?.length}
                enablePagination={false}
                renderTopToolbar={false}
                muiTablePaperProps={{
                  elevation: 0,
                  sx: {
                    boxShadow: "0",
                    height: "100%",
                  },
                }}
                muiTableBodyProps={{
                  sx: {
                    height: "100%",
                  },
                }}
                muiTableBodyRowProps={({ row }) => ({
                  id: `Customer-${(row.original as Customer).id}`,
                  onClick: () => {
                    handleCustomerClick(row.original);
                  },
                  sx: {
                    cursor: "pointer",
                    boxShadow: "0 0 0 0!important",
                  },
                })}
              />
              {customers.hasMore && (
                <div style={{ textAlign: "center", marginTop: 16 }}>
                  <Button onClick={loadMoreCustomers} loading={loading}>
                    Load More
                  </Button>
                </div>
              )}
            </>
          ) : (
            <DraggableBoard
              onDragEnd={onDragEnd}
              data={groupedFilteredCustomers}
              renderItem={renderCustomerCard}
              onLoadMore={loadMoreCustomersPerStage}
              paginationPerStage={paginationPerStage}
            />
          )}
        </Card>
      )}
    </>
  );
};

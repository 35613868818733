import React from "react";
import { Space, Dropdown, Input } from "antd";
import { PlaylistPlay } from "@mui/icons-material";
import { TagsOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { PLAYLIST_VIEW } from "./PlaylistDetails";
import { PlaylistMenu } from "./PlaylistMenu";
import { ModeButton, DropDownWrapper } from "./PlaylistDetails.styles";
import { grayBoxShadow, color } from "src/styles/variables";
import { PLAYLIST_TYPE } from "src/utils/enums";

interface ViewModeSelectorProps {
  viewMode: PLAYLIST_VIEW;
  setViewMode: (viewMode: PLAYLIST_VIEW) => void;
}

const commonDropDown = (setLoading, playlist) => {
  return (
    <Dropdown
      overlay={
        <PlaylistMenu
          currentPlaylist={playlist}
          setLoading={setLoading}
          redirectToPlaylist={true}
          linkToCopy={`${window.location.href}`}
        />
      }
      trigger={["click"]}
      overlayStyle={{ boxShadow: grayBoxShadow }}
    >
      <MoreOutlined
        style={{
          cursor: "pointer",
          fontSize: "20px",
          color: color.orange,
          transform: "rotate(90deg)",
        }}
      />
    </Dropdown>
  );
};

export const PlaylistActions = ({
  viewMode,
  setViewMode,
  setLoading,
  playlist,
  handleSearch,
}) => {
  const handleViewModeChange = (mode: PLAYLIST_VIEW) => {
    setViewMode(mode);
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Input
        placeholder="Search by title"
        allowClear
        prefix={<SearchOutlined />}
        onChange={handleSearch}
        style={{ width: "300px" }}
      />

      <Space direction="horizontal">
        {playlist.type === PLAYLIST_TYPE.SIGNAL && (
          <>
            <ModeButton
              isActive={viewMode === PLAYLIST_VIEW.CLIPS}
              onClick={() => handleViewModeChange(PLAYLIST_VIEW.CLIPS)}
            >
              <PlaylistPlay style={{ fontSize: "18px" }} />
            </ModeButton>
            <ModeButton
              isActive={viewMode === PLAYLIST_VIEW.TAGS}
              onClick={() => handleViewModeChange(PLAYLIST_VIEW.TAGS)}
            >
              <TagsOutlined style={{ fontSize: "18px" }} />
            </ModeButton>
          </>
        )}
        <DropDownWrapper>
          {commonDropDown(setLoading, playlist)}
        </DropDownWrapper>
      </Space>
    </div>
  );
};

import { FileTextOutlined } from "@ant-design/icons";
import { Space, Typography, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { getMimeTypeFix } from "../Playlist.utils";
import { capitalizeFirstLetter, formatTime } from "src/utils/utils";
import { ACCESS_TYPE } from "src/utils/enums";
import { AddRemoveTags, DeleteWithPopConfirm } from "src/components/Global";
import {
  ClipList,
  ClipItem,
  ClipActions,
  DragHandle,
  ClipPreview,
} from "./PlaylistDetails.styles";
import { useUserState } from "src/state/UserState";
import Thumbnail from "./Thumbnail/Thumbnail";
import { color, elementSize, spacing } from "src/styles/variables";

export const PlaylistClips = ({
  clips,
  handleDeleteClip,
  onDragEnd,
  disabled = true,
}) => {
  const navigate = useNavigate();
  const { user } = useUserState();

  const handleClipClick = async (clip) => {
    navigate(`?clip=${encodeURIComponent(clip.id)}`);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="clips" isDropDisabled={disabled}>
        {(provided) => (
          <ClipList ref={provided.innerRef} {...provided.droppableProps}>
            {clips.map((clip, index) => (
              <Draggable
                key={`${clip.id}-${clip.conversationId}-${index}`}
                draggableId={`${clip.id}-${clip.conversationId}-${index}`}
                index={index}
                isDragDisabled={disabled}
              >
                {(provided) => (
                  <ClipItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={() => handleClipClick(clip)}
                  >
                    <ClipPreview
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleClipClick(clip);
                      }}
                    >
                      {clip?.mimeType &&
                      getMimeTypeFix(clip.mimeType).includes("text") ? (
                        <ClipPreview>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FileTextOutlined style={{ fontSize: "24px" }} />
                          </div>
                        </ClipPreview>
                      ) : (
                        <ClipPreview
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleClipClick(clip);
                          }}
                        >
                          <Thumbnail
                            url={clip.url}
                            start={clip.start}
                            end={clip.end}
                            type={getMimeTypeFix(clip.mimeType)}
                            clipId={`${clip.id}-${clip.conversationId}`}
                          />
                        </ClipPreview>
                      )}
                    </ClipPreview>
                    <Space
                      direction="vertical"
                      style={{ paddingLeft: spacing.md, flexGrow: 1 }}
                    >
                      <Typography.Text>
                        {clip.title && capitalizeFirstLetter(clip.title)}
                      </Typography.Text>

                      <Typography.Text type="secondary">
                        {clip?.summary || ""}
                      </Typography.Text>

                      {clip?.participants?.length > 0 && (
                        <Space>
                          {clip.participants.map((participant, index) => (
                            <Tag key={index}>{participant}</Tag>
                          ))}
                        </Space>
                      )}

                      {clip?.tags?.length > 0 && (
                        <AddRemoveTags
                          onChange={null}
                          tagType={"Signal Tags"}
                          tags={clip.tags}
                          disabled={true}
                        />
                      )}
                      {clip?.updatedAt && (
                        <Typography.Text type="secondary">
                          {new Date(clip?.updatedAt).toLocaleString()}
                        </Typography.Text>
                      )}

                      <div
                        style={{
                          color: color.grayMedium,
                          paddingTop: elementSize.xxs,
                        }}
                      >
                        {clip?.timeline?.length > 0
                          ? `${clip?.timeline.length} Clips`
                          : formatTime(clip.end - clip.start)}
                      </div>
                    </Space>
                    <ClipActions>
                      {disabled && <DragHandle>☰</DragHandle>}

                      {DeleteWithPopConfirm(
                        "Delete Condition?",
                        "Are you sure you want to delete this clip? ",
                        (e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleDeleteClip(clip.id);
                        },
                        clip.user !== user?.email ||
                          user.accessType === ACCESS_TYPE.agent ||
                          user.accessType === ACCESS_TYPE.lead,
                      )}
                    </ClipActions>
                  </ClipItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ClipList>
        )}
      </Droppable>
    </DragDropContext>
  );
};

import { useState, useContext, createContext } from "react";
import { QueryConditions, dateRange } from "src/utils/types";
import dayjs from "dayjs";
import { DASHBOARD_TYPE } from "src/components/Dashboard/DashboardActions/DashboardActions";
import { ORDER_BY_DIRECTION } from "src/utils/enums";

const FiltersContext = createContext({
  filterDates: {} as { [key: string]: dateRange },
  setFilterDate: null,
  loading: false,
  setLoading: null,
  transcriptsConditions: {} as { [key: string]: QueryConditions[] },
  setTranscriptsCondition: null,
});

export const FiltersProvider = ({ children }) => {

  const [filterDates, setFilterDates] = useState({
     [DASHBOARD_TYPE.CONVERSATIONS]: {
      startDate: undefined,
      endDate: undefined,
    },
    [DASHBOARD_TYPE.INSIGHTS]: {
      startDate: dayjs().startOf("week").startOf("day"),
      endDate: undefined,
    },
  });

  const [loading, setLoading] = useState(false);

  const [transcriptsConditions, setTranscriptsConditions] = useState({
    [DASHBOARD_TYPE.CONVERSATIONS]: [{ comparisonField:"transcript_data.metadata.transcriber.created", order: ORDER_BY_DIRECTION.DESC}],
    [DASHBOARD_TYPE.INSIGHTS]: [],
  });

  const setFilterDate = (key, value) => {
    setFilterDates((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const setTranscriptsCondition = (key, value) => {
    setTranscriptsConditions((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  return (
    <FiltersContext.Provider
      value={{
        filterDates,
        setFilterDate,
        loading,
        setLoading,
        transcriptsConditions,
        setTranscriptsCondition,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersState = () => useContext(FiltersContext);

import React from "react";
import { Chart } from "react-google-charts";
import { color } from "src/styles/variables";

const SentimentChart = ({ utterances }) => {
  // Calculate sentiment percentages
  const sentimentCounts = utterances.reduce(
    (acc, utterance) => {
      const sentiment = utterance.sentiment.toLowerCase();
      acc[sentiment] = acc[sentiment] ? acc[sentiment] + 1 : 1;
      return acc;
    },
    { positive: 0, negative: 0, neutral: 0 },
  );

  const total = utterances.length;
  const positivePercentage = (sentimentCounts.positive / total) * 100;
  const negativePercentage = (sentimentCounts.negative / total) * 100;
  const neutralPercentage = (sentimentCounts.neutral / total) * 100;

  // Data for Pie Chart
  const data = [
    ["Sentiment", "Percentage"],
    ["Positive", positivePercentage],
    ["Negative", negativePercentage],
    ["Neutral", neutralPercentage],
  ];

  const options = {
    pieHole: 0.5,
    pieStartAngle: 180,
    slices: [
      { color: `${color.lightGreen}` }, // Positive - Green
      { color: `${color.red}` }, // Negative - Red
      { color: `${color.grayMedium}` }, // Neutral - Gray
    ],
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: { color: `${color.black}`, fontSize: 14 },
    },
    pieSliceText: "percentage",
    pieSliceTextStyle: {
      color: `${color.white}`,
      fontSize: 8,
    },
    chartArea: {
      width: "100%",
      height: "80%",
    },
  };

  return (
    <Chart chartType="PieChart" data={data} options={options} width="100%" />
  );
};

export default SentimentChart;

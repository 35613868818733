import { useEffect, useState } from "react";
import { useUserState } from "src/state/UserState";
import { fetchCalendarEvents, removeBot, scheduleBot } from "./Calendar.utils";
import {
  Empty,
  List,
  Switch,
  Avatar,
  Tooltip,
  Space,
  Collapse,
  Typography,
  Spin,
} from "antd";
import { color, elementSize } from "src/styles/variables";
import { INTEGRATION_ICONS } from "../../Profile";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { DashboardCalendarCollapseCard } from "../Dashboard.styles";
import { DeskTopOnly, MobileOnly } from "src/styles/stylingComponents";
import { ThunderboltFilled } from "@ant-design/icons";
import InstantBotModal from "./InstantBotModal";

const CalendarCards = () => {
  const { user } = useUserState();

  const [combinedEvents, setCombinedEvents] = useState([]);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [switchLoading, setSwitchLoading] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (e) => {
    e.stopPropagation(); // Prevent the collapse from opening
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const calendarTypes = [
    {
      key: "googleCalendar",
      calendarId: user?.googleCalendar,
      integrationExists: Boolean(user.googleCalendar),
      title: "Google Calendar",
      icon: INTEGRATION_ICONS.googleCalendar,
    },
    {
      key: "outlookCalendar",
      calendarId: user?.outlookCalendar,
      integrationExists: Boolean(user.outlookCalendar),
      title: "Outlook Calendar",
      icon: INTEGRATION_ICONS.outlookCalendar,
    },
  ];

  useEffect(() => {
    const fetchAllEvents = async () => {
      setCalendarLoading(true);
      let allEvents = [];

      for (const calendar of calendarTypes) {
        if (calendar.integrationExists && calendar.calendarId) {
          const events = await fetchCalendarEvents(calendar.calendarId);
          allEvents = [
            ...allEvents,
            ...events.map((event) => ({
              ...event,
              calendarType: calendar.key,
            })),
          ];
        }
      }

      // Sort events by start time
      allEvents.sort(
        (a, b) =>
          new Date(a.start_time).getTime() - new Date(b.start_time).getTime(),
      );
      setCombinedEvents(allEvents);
      setCalendarLoading(false);
    };

    fetchAllEvents();
  }, []);

  const handleSwitchChange = async (checked, event) => {
    setSwitchLoading((prevLoading) => ({
      ...prevLoading,
      [event.id]: true,
    }));

    if (!checked) {
      const success = await removeBot(event.id);
      if (success) {
        setCombinedEvents((prevEvents) =>
          prevEvents.map((e) => (e.id === event.id ? { ...e, bots: [] } : e)),
        );
      }
    } else {
      const botMetadata = {
        participants: JSON.stringify(event.raw.attendees),
        organization: user.organization,
        file_name: event.raw.summary,
        meeting_platform: event.meeting_platform,
      };

      const botData = await scheduleBot(
        event.id,
        event.meeting_url,
        event.start_time,
        botMetadata,
        user.organization,
        user.name,
      );
      if (botData) {
        setCombinedEvents((prevEvents) =>
          prevEvents.map((e) =>
            e.id === event.id ? { ...e, bots: botData.bots } : e,
          ),
        );
      }
    }
    setSwitchLoading((prevLoading) => ({
      ...prevLoading,
      [event.id]: false,
    }));
  };

  const renderEvents = () => {
    if (combinedEvents.length === 0) {
      return <Empty description="No upcoming meetings" />;
    }

    return (
      <List
        itemLayout="horizontal"
        dataSource={combinedEvents}
        renderItem={(event: any) => {
          const startTime = new Date(event.start_time);
          const endTime = new Date(event.end_time);
          const dayOfWeek = startTime.toLocaleDateString("en-US", {
            weekday: "long",
          });
          const formattedDate = startTime.toLocaleDateString("en-GB");
          const formattedTimeRange = `${startTime.toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit" })} - ${endTime.toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit" })}`;

          return (
            <List.Item
              actions={[
                <Tooltip
                  title={
                    event.meeting_url
                      ? event.bots && event.bots.length > 0
                        ? "The bot is currently active and will attend the meeting. To remove the bot, deactivate it."
                        : "By activating this, the bot will attend the meeting."
                      : "Missing virtual meeting link. Please enable one for Truco's participation."
                  }
                  key="bot-switch-tooltip"
                >
                  <Switch
                    checked={event.bots && event.bots.length > 0}
                    onChange={(checked) => handleSwitchChange(checked, event)}
                    loading={switchLoading[event.id]}
                    disabled={!event.meeting_url || switchLoading[event.id]}
                  />
                </Tooltip>,
              ]}
            >
              <List.Item.Meta
                title={
                  <Space direction="horizontal" size="small">
                    {event.calendarType === "googleCalendar"
                      ? INTEGRATION_ICONS.googleCalendar
                      : INTEGRATION_ICONS.outlookCalendar}
                    {event.raw.summary}
                  </Space>
                }
                description={
                  <>
                    <div>
                      {dayOfWeek} {formattedDate}
                    </div>
                    <div>{formattedTimeRange}</div>
                    <div>
                      <Avatar.Group
                        max={{
                          count: 3,
                          style: { backgroundColor: color.grayMedium },
                        }}
                        size={"small"}
                      >
                        {event.raw.attendees &&
                          event.raw.attendees.map((attendee, index) => (
                            <Tooltip
                              title={attendee?.email}
                              key={attendee?.email}
                              placement="top"
                            >
                              <Avatar
                                key={index}
                                style={{ backgroundColor: color.olive }}
                                size={"small"}
                              >
                                {attendee?.email &&
                                  attendee.email[0].toUpperCase()}
                              </Avatar>
                            </Tooltip>
                          ))}
                      </Avatar.Group>
                    </div>
                  </>
                }
              />
            </List.Item>
          );
        }}
        pagination={{
          pageSize: 2,
        }}
      />
    );
  };

  if (!user.googleCalendar && !user.outlookCalendar) {
    return null;
  }

  return (
    <>
      <DashboardCalendarCollapseCard
        defaultActiveKey={[]}
        expandIconPosition="right"
      >
        <Collapse.Panel
          header={
            <Space align="start">
              <DateRangeIcon style={{ color: color.lightOrange }} />
              <Typography.Text strong>Upcoming Calendar Events</Typography.Text>
            </Space>
          }
          extra={
            <Typography.Text
              style={{
                color: color.lightOrange,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={showModal}
            >
              <DeskTopOnly style={{ marginRight: elementSize.sm }}>
                + Add Instant Bot
              </DeskTopOnly>
              <MobileOnly>
                <Tooltip title={"Add Instant Bot"}>
                  <ThunderboltFilled />
                </Tooltip>
              </MobileOnly>
            </Typography.Text>
          }
          key="1"
        >
          <Spin spinning={calendarLoading}>{renderEvents()}</Spin>
        </Collapse.Panel>
      </DashboardCalendarCollapseCard>
      <InstantBotModal visible={isModalVisible} onClose={closeModal} />
    </>
  );
};

export default CalendarCards;

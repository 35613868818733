export enum ProfileTabs {
  EVALUATION = "Evaluation",
  ASSIGNMENTS = "Assignments",
  CALIBRATIONS = "Calibrations",
  SETTINGS = "Settings",
  COLLABORATORS = "Collaborators",
  INTEGRATIONS = "Integrations",
  HOME = "Home",
  SIGNALS = "Signals",
  SUBSCRIPTION = "Subscription",
  CALENDAR = "Calendar",
}

export const ProfileDocs = {
  EVALUATION: `${process.env.REACT_APP_TRUCO_DOCS}/docs/getting-started/custom-scorecard`,
  ASSIGNMENTS: `${process.env.REACT_APP_TRUCO_DOCS}/docs/quality-assurance/assignments`,
  CALIBRATIONS: `${process.env.REACT_APP_TRUCO_DOCS}/docs/quality-assurance/calibrations`,
  SETTINGS: "",
  COLLABORATORS: `${process.env.REACT_APP_TRUCO_DOCS}/docs/getting-started/teams`,
  INTEGRATIONS: `${process.env.REACT_APP_TRUCO_DOCS}/docs/category/integrations`,
  SIGNALS: "",
};

export enum SETTINGS_DESCRIPTION {
  showRisk = "Switch between focusing on wins (80% score) or highlighting losses (20% risk) based on your preference.",
  showSentiment = "Turn on sentiment analysis to understand conversations better.",
  conversationProperties = "Properties added to every conversation",
  customerStages = "Stages of a customer journey",
  emailConversationSummary = "Subscribe to send post-call summaries and key topics via email—exclusively to external participants.",
}

export enum FORM_TYPE {
  EVALUATION = "evaluation",
  SIGNALS = "signals",
}

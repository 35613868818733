import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Input,
  message,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import { useOrganizationState } from "src/state/OrganizationState";
import ClipPlayer, { ClipPlayerRef } from "../ClipPlayer/ClipPlayer";
import { Container } from "../ClipPlayer/ClipPlayer.styles";
import { decryptId } from "src/utils/utils";
import { OpenInNew } from "@mui/icons-material";
import { useModal } from "src/state/UseModal";
import { ClipActions, Section } from "./PlaylistDetails.styles";
import { PoweredByPublicFooter } from "src/components/Global";
import { elementSize } from "src/styles/variables";
import {
  getClipInfoFromPlaylist,
  getMimeTypeFix,
  hasEditAccess,
  updateClipSummaryInPlaylist,
} from "../Playlist.utils";
import { fetchFileUrl } from "src/components/Dashboard/Dashboard.utils";
import { COLLECTION_DATA, QUERY_PARAMS } from "src/utils/enums";
import { getRecordFromFireStore, updateDocInFireStore } from "src/firebaseAuth";
import { playlistConverter } from "src/utils/converter";
import { UtterancesList } from "src/components/Dashboard/ConversationModal/ConversationModalBody/UtterancesList";
import { useUserState } from "src/state/UserState";
import {
  ClipFields,
  fieldsToDisplay,
} from "../ClipDetailsModalContent.constants";
import { INTEGRATION_ICONS } from "src/components/Profile/Components/Intergrations/Integrations.variables";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";

const ClipDetailsModalContent = () => {
  const { modalState } = useModal();
  const { user } = useUserState();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const isShareableClip = !!queryParams.get(QUERY_PARAMS.SHAREABLE_CLIP); // Check if 'sharableClip=true'
  const pathSegments = location.pathname.split("/").filter(Boolean);

  const organizationId = pathSegments[1] && decryptId(pathSegments[1]);
  const [clipOrganizationId, setClipOrganizationId] = useState(organizationId);
  const playlistId = pathSegments[2] && decryptId(pathSegments[2]);

  const clipId = modalState.id;
  const clipPlayerRef = useRef<ClipPlayerRef>(null);
  const { organization, collaborators, playlists } = useOrganizationState();

  const [selectedClip, setSelectedClip] = useState(null);

  const [signalPlaylistUser, setSignalPlaylistUser] = useState(null);
  const [currentUtteranceIndex, setCurrentUtteranceIndex] = useState<
    number | null
  >(null);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [utterances, setUtterances] = useState([]);
  const [loadingUtterances, setLoadingUtterances] = useState(true);
  const [showHighlights, setShowHighlights] = useState<boolean>(false);
  const [currentTranscript, setCurrentTranscript] = useState(null);

  const [editMode, setEditMode] = useState(false);
  const [editedValue, setEditedValue] = useState("");
  const [currentPlaylist, setCurrentPlaylist] = useState(null);

  const fetchPlaylistFromFirestore = async () => {
    const {
      foundClip: clipFound,
      playlistUser,
      playlist,
    } = await getClipInfoFromPlaylist(playlistId, clipId, organizationId);
    setCurrentPlaylist(playlist);
    const url = await fetchFileUrl(clipFound.conversationId);
    setSelectedClip({ ...clipFound, url });
    setSignalPlaylistUser(playlistUser);
  };

  const fetchUtterances = async () => {
    const transcript = await getRecordFromFireStore(
      `organization/${organizationId}/${COLLECTION_DATA.CONVERSATIONS}/${selectedClip.conversationId}`,
    );
    setCurrentTranscript(transcript);
    let utterances = transcript?.transcript_data?.utterances;
    if (selectedClip.timeline && selectedClip.timeline.length > 0) {
      utterances = transcript?.transcript_data?.utterances.filter(
        (utterance) => {
          return selectedClip.timeline.some(
            (timeRange) =>
              utterance.start >= timeRange.start &&
              utterance.end <= timeRange.end,
          );
        },
      );
    } else {
      utterances = transcript?.transcript_data?.utterances.filter(
        (utterance) =>
          utterance.start >= selectedClip.start &&
          utterance.end <= selectedClip.end,
      );
    }
    setUtterances(utterances);
    setLoadingUtterances(false);
  };

  const fetchSharableClip = async () => {
    const clipData = await getRecordFromFireStore(
      `${COLLECTION_DATA.CLIPS}/${clipId}`,
    );
    setClipOrganizationId(clipData.organizationId);
    const url = await fetchFileUrl(clipData.conversationId);
    setSelectedClip({ ...clipData, url });
    setUtterances(clipData.utterances);
  };

  useEffect(() => {
    const fetchClipDetails = async () => {
      if (clipId) {
        const playlist = playlists?.data?.find(
          (playlist) => playlist.id === playlistId,
        );
        if (playlist) {
          setCurrentPlaylist(playlist);
          const clip =
            playlist?.clips?.find((clip) => clip.id === clipId) || null;
          setSignalPlaylistUser(playlist?.createdBy);
          const url = await fetchFileUrl(clip.conversationId);
          setSelectedClip({ ...clip, url });
        } else {
          fetchPlaylistFromFirestore();
        }
      }
    };
    if (isShareableClip) {
      fetchSharableClip();
    } else {
      fetchClipDetails();
    }
  }, [clipId]);

  useEffect(() => {
    if (!selectedClip || isShareableClip) return;
    setStartTime(selectedClip?.start);
    if (
      !getMimeTypeFix(selectedClip.mimeType).includes("text") ||
      selectedClip.timeline?.length > 0
    ) {
      fetchUtterances();
    } else {
      setUtterances(selectedClip.utterances);
      setLoadingUtterances(false);
    }
  }, [selectedClip]);

  useEffect(() => {
    return () => {
      if (clipPlayerRef.current) {
        clipPlayerRef.current.pause();
        clipPlayerRef.current = null;
      }
    };
  }, []);

  const handleDeleteClipFromPlaylistSignal = async (timelineIndex) => {
    // Remove the specific timeline item from the clip
    const updatedTimeline = selectedClip.timeline.filter(
      (_, index) => index !== timelineIndex,
    );
    const updatedClip = {
      ...selectedClip,
      updatedAt: new Date().toISOString(),
      timeline: updatedTimeline,
    };
    // Update the local state
    setSelectedClip(updatedClip);
    const playlistPath = `/organization/${organizationId}/${COLLECTION_DATA.PLAYLISTS}/${playlistId}`;

    try {
      // Get the existing playlist from Firestore
      const existingPlaylist = await getRecordFromFireStore(
        playlistPath,
        playlistConverter,
      );
      // Find the index of the clip to update in the playlist
      const updatedClips = existingPlaylist.clips.map((clip) =>
        clip?.conversationId === selectedClip?.conversationId
          ? updatedClip
          : clip,
      );

      // Create the updated playlist data
      const updatedPlaylist = {
        ...existingPlaylist,
        clips: updatedClips,
      };

      // Update the Firestore document
      await updateDocInFireStore(
        playlistPath,
        updatedPlaylist,
        playlistConverter,
      );
      message.success("Clip removed succesfully");
      // If the clip is removed, close the modal
      console.log("Timeline item removed and Firestore updated successfully");
    } catch (error) {
      message.error("Clip failed to remove");
      console.log("Error updating Firestore:", error);
    }
  };

  const handleUtteranceClick = (index) => {
    if (getMimeTypeFix(selectedClip.mimeType).includes("text")) return;
    setCurrentUtteranceIndex(index);
    const utterance = utterances[index];
    setStartTime(utterance.start);
  };

  const updatedUtteranceIndex = (time) => {
    const utteranceIndex = utterances?.findIndex(
      (utterance) => utterance.start <= time && utterance.end >= time,
    );
    setCurrentUtteranceIndex(utteranceIndex);
  };

  const handleEditClick = () => {
    setEditMode(true);
    setEditedValue(selectedClip?.summary || "");
  };

  const handleSaveClick = async () => {
    try {
      updateClipSummaryInPlaylist(
        organization,
        playlistId,
        selectedClip.conversationId,
        clipId,
        editedValue,
        currentPlaylist,
      );

      const updatedClip = {
        ...selectedClip,
        summary: editedValue,
        updatedAt: new Date().toISOString(),
      };

      setSelectedClip(updatedClip);
      message.success("Clip updated successfully");
      setEditMode(false);
    } catch (e) {
      console.log("Error updating clip:", e);
      message.error("Failed to update clip");
    }
  };

  return (
    <Spin spinning={!selectedClip}>
      <ClipActions style={{ flexDirection: "column", gap: elementSize.md }}>
        {selectedClip && (
          <>
            <Row gutter={[16, 32]}>
              <Col xl={17} xs={24}>
                <Card>
                  <Row gutter={[16, 8]}>
                    {(getMimeTypeFix(selectedClip.mimeType).includes("audio") ||
                      getMimeTypeFix(selectedClip.mimeType).includes(
                        "video",
                      )) && (
                      <Col
                        xs={24}
                        xl={
                          getMimeTypeFix(selectedClip.mimeType).includes(
                            "video",
                          )
                            ? showHighlights
                              ? 24
                              : 17
                            : 24
                        }
                      >
                        {selectedClip.timeline &&
                        selectedClip?.timeline?.length > 0 ? (
                          <ClipPlayer
                            url={selectedClip.url}
                            timeline={selectedClip.timeline}
                            mimeType={getMimeTypeFix(selectedClip.mimeType)}
                            ref={clipPlayerRef}
                            handleDeleteClipFromPlaylistSignal={
                              handleDeleteClipFromPlaylistSignal
                            }
                            signalPlaylistUser={signalPlaylistUser}
                            showHighlights={showHighlights}
                            setShowHighlights={setShowHighlights}
                            updatedUtteranceIndex={updatedUtteranceIndex}
                            enableHighlight={
                              selectedClip.timeline &&
                              selectedClip?.timeline.length > 1
                            }
                            start={startTime}
                          />
                        ) : (
                          <ClipPlayer
                            url={selectedClip.url}
                            timeline={[
                              {
                                start: selectedClip.start,
                                end: selectedClip.end,
                              },
                            ]}
                            mimeType={getMimeTypeFix(selectedClip.mimeType)}
                            updatedUtteranceIndex={updatedUtteranceIndex}
                            start={startTime}
                          />
                        )}
                      </Col>
                    )}
                    {!showHighlights && utterances?.length > 0 && (
                      <Col
                        xs={24}
                        xl={
                          getMimeTypeFix(selectedClip.mimeType).includes(
                            "video",
                          )
                            ? 7
                            : 24
                        }
                      >
                        <Spin spinning={loadingUtterances}>
                          <Container>
                            <UtterancesList
                              utterances={utterances}
                              currentUtteranceIndex={currentUtteranceIndex}
                              {...(getMimeTypeFix(
                                selectedClip.mimeType,
                              ).includes("text")
                                ? {}
                                : { handleUtteranceClick })}
                              action={false}
                            />
                          </Container>
                        </Spin>
                      </Col>
                    )}
                  </Row>
                </Card>
              </Col>

              <Col xl={7} xs={24}>
                <Card
                  title={<Typography.Text strong>Clip Details</Typography.Text>}
                  style={{
                    width: "100%",
                  }}
                  extra={
                    hasEditAccess(currentPlaylist, user) &&
                    (!editMode ? (
                      <EditOutlined onClick={handleEditClick} />
                    ) : (
                      <SaveOutlined onClick={handleSaveClick} />
                    ))
                  }
                >
                  {fieldsToDisplay.map(
                    ({ key, label }) =>
                      selectedClip?.[key] && (
                        <div key={key} style={{ marginBottom: 16 }}>
                          <Typography.Paragraph>
                            <Section>
                              <strong>{label}:</strong>
                            </Section>
                          </Typography.Paragraph>
                          {key === ClipFields.Participants ? (
                            <Space>
                              {selectedClip[key].map((p, i) => (
                                <Tag key={i}>{p}</Tag>
                              ))}
                            </Space>
                          ) : key === ClipFields.UpdatedAt ? (
                            <Typography.Paragraph>
                              {new Date(selectedClip[key]).toLocaleString()}
                            </Typography.Paragraph>
                          ) : key === ClipFields.ClippedBy ? (
                            !selectedClip?.timeline && (
                              <Tag key={key}>{selectedClip.user}</Tag>
                            )
                          ) : key === "summary" ? (
                            <>
                              {editMode ? (
                                <Input.TextArea
                                  value={editedValue}
                                  onChange={(e) =>
                                    setEditedValue(e.target.value)
                                  }
                                  rows={4}
                                />
                              ) : (
                                <Typography.Paragraph>
                                  {selectedClip[key]}
                                </Typography.Paragraph>
                              )}
                            </>
                          ) : (
                            <Typography.Paragraph>
                              {selectedClip[key]}
                            </Typography.Paragraph>
                          )}
                        </div>
                      ),
                  )}
                  {currentTranscript?.transcript_data.metadata.file_source && (
                    <Typography.Paragraph>
                      <Section>
                        <strong>File Source:</strong>{" "}
                        {
                          INTEGRATION_ICONS?.[
                            currentTranscript?.transcript_data.metadata
                              .file_source
                          ]
                        }
                      </Section>
                    </Typography.Paragraph>
                  )}
                </Card>
              </Col>
            </Row>
          </>
        )}

        {clipOrganizationId === organization ? (
          <Button
            onClick={() => {
              navigate(
                `?conversation=${encodeURIComponent(selectedClip.conversationId)}`,
              );
            }}
          >
            <OpenInNew style={{ fontSize: "16px", cursor: "pointer" }} /> View
            full conversation
          </Button>
        ) : (
          <PoweredByPublicFooter />
        )}
      </ClipActions>
    </Spin>
  );
};

export default ClipDetailsModalContent;

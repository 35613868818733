import { Chart } from "react-google-charts";
import { color } from "src/styles/variables";

export const PlaylistTrendCard = ({ playlist, numDays = 30 }) => {
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - numDays);

  // Create an array with the last 30 days
  const lastXDays = Array.from({ length: numDays }, (_, i) => {
    const date = new Date(thirtyDaysAgo);
    date.setDate(date.getDate() + i);
    return date.toISOString().split("T")[0];
  });

  const clipCounts = playlist.clips.reduce((acc, clip) => {
    const clipDate = new Date(clip.updatedAt).toISOString().split("T")[0];
    if (clipDate >= lastXDays[0]) {
      acc[clipDate] = (acc[clipDate] || 0) + 1;
    }
    return acc;
  }, {});

  const aggregatedData = lastXDays.map((date) => [date, clipCounts[date] || 0]);

  if (!aggregatedData?.length) return null;

  const chartData = [["Date", "Clips"], ...aggregatedData];

  return (
    <Chart
      width={numDays > 30 ? "100%" : "200px"}
      height={numDays > 30 ? "100px" : "50px"}
      chartType="ColumnChart"
      loader={<div>Loading Chart...</div>}
      data={chartData}
      options={{
        hAxis: {
          textPosition: "none",
          gridlines: { color: "transparent" },
          baselineColor: "transparent",
          ticks: [],
        },
        vAxis: {
          textPosition: "none",
          gridlines: { color: "transparent" },
          baselineColor: "transparent",
          ticks: [],
        },
        legend: { position: "none" },
        axisTitlesPosition: "none",
        chartArea: {
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        },
        tooltip: { trigger: "hover" },
        backgroundColor: "transparent",
        colors: [color.orange],
        animation: { startup: true, duration: 1000, easing: "out" },
      }}
    />
  );
};

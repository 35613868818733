import React, { useEffect, useMemo, useState } from "react";
import { Spin, Empty, Space, Typography, Progress, Breadcrumb } from "antd";
import { capitalizeFirstLetter } from "src/utils/utils"; // Your existing utility function
import { getRecordFromFireStore } from "src/firebaseAuth";
import { color, spacing, elementSize } from "src/styles/variables";
import { SpaceBetweenDiv } from "src/components/Profile/Profile.styles";
import { ClipList, ClipItem } from "./PlaylistDetails.styles";
import { PlaylistClips } from "./PlaylistClips";

interface TagsViewProps {
  organizationId: string;
  playlistId: string;
  clips: any;
  setBreadCrumbs: any;
  breadcrumbs: any;
  playlistTitle: string;
  searchValue: string;
}

const TagsView: React.FC<TagsViewProps> = ({
  organizationId,
  playlistId,
  clips,
  setBreadCrumbs,
  breadcrumbs,
  playlistTitle,
  searchValue,
}) => {
  const [tagsWithCount, setTagsWithCount] = useState([]);
  // const [filteredTags, setFilteredTags] = useState(tagsWithCount);
  const [tagLoading, setTagLoading] = useState(false); // Loading for tag calculation
  const [selectedTheme, setSelectedTheme] = useState<any>(null); // Selected tag state
  const [themeClips, setThemeClips] = useState<any[]>([]); // State for clips related to the selected tag

  // Fetch tags when the component is mounted
  useEffect(() => {
    const fetchTags = async () => {
      setTagLoading(true);
      try {
        const doc = await getRecordFromFireStore(
          `/organization/${organizationId}/playlists/${playlistId}/themes/themes`,
        );
        let sortedThemes = doc.themes?.sort((a,b)=> b?.count - a?.count)
        setTagsWithCount(sortedThemes);
      } catch (error) {
        console.error("Error fetching tags: ", error);
      } finally {
        setTagLoading(false);
      }
    };

    if (tagsWithCount.length === 0) {
      fetchTags();
    }
  }, [organizationId, playlistId]);

  const handleTagClick = async (theme: any) => {
    setSelectedTheme((prevTheme) => {
      if (prevTheme !== theme) {
        setBreadCrumbs([
          {
            label: theme.tag,
          },
        ]);
        return theme;
      }
      setBreadCrumbs([]);
      return null;
    });

    const relatedClips = clips.filter((clip: any) =>
      clip?.tags?.includes(theme.tag),
    );
    setThemeClips(relatedClips);
  };

  const filteredTags = useMemo(() => {
    return tagsWithCount.filter((tag) =>
      tag.tag.toLowerCase()?.includes(searchValue.toLowerCase()),
    );
  }, [searchValue, tagsWithCount]); // Dependencies: only re-compute when searchValue or tagsWithCount change

  return (
    <>
      <Spin spinning={tagLoading}>
        {filteredTags.length === 0 ? (
          <Empty description="No Tags found!" />
        ) : selectedTheme ? (
          <>
            {breadcrumbs?.length > 0 && (
              <Breadcrumb style={{ cursor: "pointer" }}>
                <Breadcrumb.Item
                  key={"playlist"}
                  onClick={() => {
                    setSelectedTheme(null);
                    setBreadCrumbs([]);
                    setThemeClips([]);
                  }}
                >
                  <span> {playlistTitle} </span>
                </Breadcrumb.Item>
                {breadcrumbs.map((crumb, index) => (
                  <Breadcrumb.Item key={index}>
                    <span> {crumb.label}</span>
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
            )}
            <PlaylistClips
              clips={themeClips}
              disabled={true}
              handleDeleteClip={null}
              onDragEnd={null}
            />
          </>
        ) : (
          <div>
            <SpaceBetweenDiv
              style={{ marginBottom: "16px", padding: `0 ${elementSize.md}` }}
            >
              <Typography.Text strong>Title</Typography.Text>
              <Typography.Text strong>Count & Volume</Typography.Text>
            </SpaceBetweenDiv>
            <ClipList>
              {filteredTags.map((theme: any) => (
                <ClipItem key={theme.tag} onClick={() => handleTagClick(theme)}>
                  <SpaceBetweenDiv
                    style={{ paddingLeft: spacing.md, flexGrow: 1 }}
                  >
                    <Space direction="vertical">
                      <Typography.Text>
                        {capitalizeFirstLetter(theme.tag)}
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        {capitalizeFirstLetter(theme.description)}
                      </Typography.Text>
                    </Space>
                    <Space direction="vertical" style={{ marginLeft: "auto" }}>
                      <div
                        style={{
                          color: color.grayMedium,
                          paddingTop: elementSize.xxs,
                        }}
                      >
                        {theme.count} clips
                      </div>
                      <Progress
                        type="dashboard"
                        percent={Math.floor((theme.count / clips.length) * 100)}
                        strokeColor={color.orange}
                        size={40}
                      />
                    </Space>
                  </SpaceBetweenDiv>
                </ClipItem>
              ))}
            </ClipList>
          </div>
        )}
      </Spin>
    </>
  );
};

export default TagsView;
